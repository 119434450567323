<script>
import Agreement from '@/components/shared/agreements/Agreement'
import BrowserAlert from '@/components/shared/BrowserAlert'
import StoreService from '@/services/StoreService'

export default {
	name: 'Intro',
	components: { BrowserAlert, Agreement },
	data: () => ({
		layout: 'public-layout',
		location: 'INTRO',
		allAgreed: null,
		showView: false,
		loading: true
	}),
	created: async function () {
		try {
			await StoreService.populateAgreements()
		} catch (error) {
			console.error('Could not load agreements', error)
		}
		this.$store.commit('cms/setResetCheckpoint', false)
		this.loading = false
	},
	methods: {
		goToEnrollment: async function () {
			this.$store.commit('cms/setIntroCheckpoint', true)
			await this.$router.replace({ name: 'enrollment' })
		},
		handleAllAgreed: function (allAgreed) {
			this.allAgreed = allAgreed
			this.showView = true
		}
	}
}
</script>
<template>
	<component :is="layout" class="intro">
		<browser-alert class="intro__alert" v-show="showView"/>
		<div class="intro__body" v-show="showView">
			<h2 class="intro__title">
				{{ $t('intro.title') }}
			</h2>
			<agreement v-if="!loading"
					:location="location"
					data-testid="agreement-component"
					ref="agreementComponent"
					@no-agreements="goToEnrollment()"
					@all-agreed="handleAllAgreed" />
			<base-button class="intro__button"
					data-testid="base-button-intro"
					:disabled="!allAgreed"
					modifiers="accent large set-height margin-y"
					@click="goToEnrollment">{{ $t('intro.continue') }}</base-button>
		</div>
	</component>
</template>
<style lang="scss" scoped>
.intro {
	&__alert {
		border-radius: var(--border-radius);
		border: 1px solid var(--border-variant-light);
		margin-bottom: 1rem;
	}
	&__body {
		background-color: var(--surface-colour);
		border-radius: var(--border-radius);
		border: 1px solid var(--border-variant-light);
		color: var(--on-surface);
		padding: 2rem 1rem;
	}
	&__title {
		font-size: var(--font-size-subheading);
		font-weight: var(--font-weight-subheading);
		letter-spacing: var(--letter-spacing-expanded);
		text-align: center;
	}
}
</style>
